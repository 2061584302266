import React from "react";

import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";

const ReactApp = () => {
  return (
    <WhatsAppWidget
      phoneNo={process.env.RECEIVER_PHONE}
      position="right"
      widgetWidth="300px"
      widgetWidthMobile="260px"
      autoOpen={true}
      autoOpenTimer={3000}
      messageBox={false}
      messageBoxTxt=""
      iconSize="50"
      iconColor="white"
      iconBgColor="#00E676"
      headerIcon="https://res.cloudinary.com/dzoshddvt/image/upload/v1677459505/Portfolio_Images/logo.4ab11c35_iiliez.png"
      headerIconColor="pink"
      headerTxtColor="black"
      headerBgColor="#00E676"
      headerTitle="Luis Urdaneta"
      headerCaption="Online"
      bodyBgColor="#fff"
      chatPersonName="Support"
      chatMessage={
        <>
          Hi there 👋 <br />
          <br /> How can I help you?
        </>
      }
      footerBgColor="#fafbfd"
      placeholder="Type a message.."
      btnBgColor="yellow"
      btnTxt="Iniciar Chat"
      btnTxtColor="black"
    />
  );
};

export default ReactApp;
